import React from "react";
import { 
    MainStyled, 
    SectionStyled, 
    VersionStyled, 
    TitleStyled,
    TableStyled,
    TrHeaderStyled,
    TrStyled,
    TdStyled,
    ListStyled,
} from './styles';


const PrivacyNotice = function() {
    return (
        <MainStyled>
            <SectionStyled>
                <h1>Aviso de privacidade</h1>
                <VersionStyled>Atualizada em 14 de janeiro de 2025.</VersionStyled>

                <TitleStyled>1. Declaração de Privacidade</TitleStyled>
                <p>
                    A <strong>EYECARE</strong> sabe o quanto é importante conhecer e estar seguro sobre a utilização dos dados pessoais 
                    que tem em seu poder. Por isso, nos preocupamos em esclarecer e explicar o nosso Aviso de Privacidade.
                </p>
                <p>
                    Nós reconhecemos a necessidade de proteção e de gerenciamento adequado das informações pessoais coletadas 
                    em nosso site, bem como aquelas que nos são compartilhadas por nossos clientes. O presente Aviso de 
                    Privacidade auxiliará a compreender quais os tipos de informações que podemos coletar, como essas informações 
                    poderão ser utilizadas, com quem as informações poderão ser compartilhadas e quais os direitos dos titulares 
                    no que diz respeito à proteção de seus dados pessoais.
                </p>

                <TitleStyled>2. Quais são as informações pessoais que coletamos</TitleStyled>
                <p>
                    As informações pessoais que obtemos podem ser coletadas das seguintes formas:
                </p>
                <p>
                    <strong>Site:</strong> Informações pessoais são coletadas quando o usuário interage em nosso site por meio de formulário de contato 
                    ou quando há o preenchimento do formulário de anúncios de publicidade. Essas informações pessoais incluem nome, e-mail, CRM do médico, 
                    telefone e, quando o caso, nome da empresa. Somente é necessária a coleta de dados básicos quando o usuário desejar o retorno de seu contato. 
                    Todas as informações pessoais são colhidas de maneira justa e não invasiva, com o seu consentimento voluntário. As informações pessoais 
                    não são acessíveis a qualquer pessoa fora da função específica para a qual foram coletadas.
                </p>
                <p>
                    <strong>Aplicativos da Eyecare:</strong> disponibilizamos aos nossos clientes os aplicativos Benefício Visão, Glauco e o Eyecare BI, os 
                    quais possuem sistemas que coletam informações e atividades dos usuários por meio do acesso aos aplicativos/softwares em seus smartphones 
                    e computadores.
                </p>
                <p>
                    Os aplicativos podem registrar informações como: dados de login (e-mail e senha), quantas vezes o usuário usa o sistema e o tempo 
                    de utilização do aplicativo, as atividades que ocorrem dentro do aplicativo, uso agregado, dados de desempenho e de onde o 
                    aplicativo foi baixado ou utilizado.
                </p>
                <p>
                    Quando você usa nossos aplicativos, podemos também coletar a sua geolocalização, o modelo e a versão do dispositivo, o identificador 
                    de dispositivo (ou “UDID”) e a versão do sistema operacional. Geolocalização: sua localização está sujeita às configurações ajustadas 
                    no seu dispositivo e pode ser determinada por GPS, endereço IP, dados do sensor do seu dispositivo ou por meio de informações 
                    transmitidas por itens próximos do dispositivo como, por exemplo, pontos de acesso Wi-Fi, dispositivos com bluetooth ativado 
                    e torres de celular.
                </p>
                <p>
                    Enviamos notificações push de vez em quando para manter você informado sobre alertas programados, eventos ou promoções. Caso 
                    não queira receber essas comunicações, desative-as no seu dispositivo.
                </p>
                <p>
                    Nós podemos vincular os dados que armazenamos no software a dados pessoais que você envia pelo aplicativo. Fazemos isso para 
                    aprimorar os serviços que prestamos e melhorar a funcionalidade do nosso marketing, das nossas análises de dados e das Plataformas.
                </p>
                <p>
                    <strong>Newsletter:</strong> O Usuário pode optar por receber nossa newsletter, contendo novidades, artigos e promoções, por meio do preenchimento 
                    do e-mail em formulário próprio em nosso site. O usuário pode, a qualquer momento, deixar de receber nossas comunicações clicando 
                    no link de cancelamento contido no rodapé dos próprios e-mails enviados.
                </p>
                <p>
                    <strong>Canais de atendimento:</strong> O usuário poderá entrar em contato por meio do WhatsApp. Os dados pessoais são coletados com o objetivo de 
                    identificar o usuário, bem como para atender sua solicitação e prestar suporte. Esses podem incluir, dentre outros, o nome, e-mail e telefone. Além destes, 
                    coletamos qualquer dado pessoal que seja informado espontaneamente pelo usuário.
                </p>
                <p>
                    <strong>Cookies:</strong> Dados pessoais podem ser coletados por meio de cookies. Para mais informações, consulte nossa <a href="/termos-e-politicas/politica-de-cookies">Política de Cookies.</a>
                </p>
                <p>
                    <strong>Cadastro:</strong> Coletamos os dados de nossos clientes necessários para efetuar o cadastro em nosso site ou aplicativos. Os dados são necessários para identificar o usuário e permitir que ele adquira produtos ou serviços através de nossas plataformas. Os dados coletados para cadastro são nome, sobrenome, e-mail e CPF do usuário.
                </p>
                <p>
                    <strong>Contrato:</strong> Quando o usuário adquire nossos produtos ou serviços, coletamos os dados necessários para a confecção do contrato e emissão de recibo ou nota fiscal. Estes dados são, entre outros, o e-mail para contato e eventual envio de Nota Fiscal Eletrônica, nome completo ou razão social, nome completo do Responsável, CPF, 
                    CNPJ, telefone, endereço comercial completo com CEP, Bairro, Cidade e Estado e a Inscrição Estadual, quando aplicável.
                </p>
                <p>
                    <strong>Telemedicina:</strong> disponibilizamos o agendamento de consultas on-line com um de nossos médicos, especialistas em telemedicina, por meio das nossas plataformas e por meio de um canal de atendimento que pode ser chat ou Whatsapp. Neste caso, coletamos os dados necessários para a confecção do agendamento e emissão de recibo ou 
                    nota fiscal. Estes dados são, entre outros como: nome completo ou o nome completo do responsável (em caso de titulares menores de idade), CPF, telefone, endereço, e-mail e dados relacionados à saúde do paciente.
                </p>
                <p>
                    <strong>Comentários e depoimentos de usuários em nosso site:</strong> Nós postamos em nossas Plataformas depoimentos e comentários de usuários como você, que podem conter dados pessoais. Nós obtemos o consentimento de cada usuário, via e-mail, antes de postar seu nome e depoimento.
                </p>
                <p>
                    <strong>Compartilhamento:</strong> Eventualmente, teremos acesso a dados pessoais compartilhados por meio de fontes externas, como de fontes publicamente disponíveis, como redes sociais e órgãos governamentais, ou parceiros com os quais atuamos, de forma conjunta, em co-branding, publicidade ou marketing. 
                </p>
                <p>
                    <strong>Eventos:</strong> Informações pessoais, como nome, telefone e e-mail de contato, também podem ser coletadas em meio físico ou digital pelos nossos empregados em feiras, campanhas, exposições e eventos promovidos pela <strong>EYECARE</strong>.
                </p>
                <p>
                    <strong>Candidatos a vagas de emprego:</strong> Podemos ter acesso a informações pessoais de candidatos a vagas de emprego, tais como nome completo, telefone, e-mail, área de formação, município de interesse, cargo desejado e quaisquer outras informações fornecidas voluntariamente pelo candidato.
                </p>
                <p>
                    <strong>Empregados:</strong> Quanto aos dados pessoais coletados de nossos empregados, estes são necessários para que seja efetuado o registro do empregado, em atendimento à legislação trabalhista e execução do contrato de trabalho.
                </p>

                <TitleStyled>3. Mídias Sociais</TitleStyled>
                <p>
                    A <strong>EYECARE</strong> se utiliza também da Mídia Social para se comunicar e interagir com seus clientes e consumidores, por meio de websites de terceiros como, por exemplo, o Facebook, Instagram, LinkedIn, Facebook e Widgets, 
                    como o botão de compartilhar ou mini-programas interativos que são executados em nossos sites. Estes websites de terceiros são uma tecnologia baseada na Internet que não é operada ou controlada pela <strong>EYECARE</strong>. Ao interagir, 
                    compartilhar ou “Curtir” a página da <strong>EYECARE</strong> no Facebook, Instagram, LinkedIn, Facebook ou outra mídia social, você poderá revelar determinadas informações pessoais a <strong>EYECARE</strong> ou a terceiros.
                </p>
                <p>
                    Usamos os “botões sociais” para permitir que os nossos usuários compartilhem ou marquem páginas da web. São botões de sites terceiros de mídias sociais e que podem registrar informações sobre suas atividades na internet, incluindo este site. Por favor, 
                    reveja os respectivos termos de uso e políticas de privacidade dessas plataformas para entender exatamente como elas usam suas informações, bem como optar por não receber ou excluir tais informações.
                </p>
                <p>
                    A quantidade de informações pessoais visíveis dependerá das suas próprias configurações de privacidade no Facebook, Instagram, LinkedIn e demais mídias sociais.
                </p>

                <TitleStyled>4. Finalidades e bases legais</TitleStyled>
                <p>
                    Relacionamos a seguir as finalidades e bases legais dos tratamentos de dados da <strong>EYECARE:</strong>
                </p>
                <TableStyled>
                    <thead>
                        <TrHeaderStyled>
                            <TdStyled>Fins</TdStyled>
                            <TdStyled>Base legal</TdStyled>
                        </TrHeaderStyled>
                    </thead>
                    <tbody>
                        <TrStyled>
                            <TdStyled>Cumprir o contrato estabelecido com nossos clientes.</TdStyled>
                            <TdStyled>Será determinada pelo nosso cliente, na qualidade de controlador dos dados pessoais.</TdStyled>
                        </TrStyled>
                        <TrStyled>
                            <TdStyled>Cumprir com termos e condições estabelecidos na contratação de serviços médicos por nossos pacientes ou seus responsáveis legais</TdStyled>
                            <TdStyled>Necessário para execução de contrato no qual o titular de dados pessoais seja parte, após autorização em Termo de Consentimento Livre e Esclarecido para tratamento de dados relacionados à saúde dos titulares; <br/><br/> ou Para a tutela da saúde, em procedimento realizado por profissionais da área da saúde ou por entidades sanitárias.</TdStyled>
                        </TrStyled>
                        <TrStyled>
                            <TdStyled>Recrutamento e avaliação de aderência à vaga de trabalho.</TdStyled>
                            <TdStyled>Justificado com base em nossos legítimos interesses para assegurar que recrutamos os empregados adequados.</TdStyled>
                        </TrStyled>
                        <TrStyled>
                            <TdStyled>Comunicação com você, nos casos não especificados neste quadro (inclusive em casos de emergência e para fornecer a você informações solicitadas)</TdStyled>
                            <TdStyled>Justifica-se com base em nossos interesses legítimos de assegurar comunicação e gestão de emergências adequadas na organização.</TdStyled>
                        </TrStyled>
                        <TrStyled>
                            <TdStyled>Cumprir requisitos legais.</TdStyled>
                            <TdStyled>Necessário para a conformidade com uma obrigação legal à qual estamos sujeitos.</TdStyled>
                        </TrStyled>
                        <TrStyled>
                            <TdStyled>Monitorar o uso que você faz dos nossos sistemas (incluindo monitorar o uso do nosso site e de quaisquer aplicativos e ferramentas que você utilizar).</TdStyled>
                            <TdStyled>Justifica-se com base nos nossos interesses legítimos de evitar não conformidades e proteger nossa reputação.</TdStyled>
                        </TrStyled>
                        <TrStyled>
                            <TdStyled>
                                Escuta social (identificando e analisando o que está sendo dito sobre a <strong>EYECARE</strong> nas mídias 
                                sociais [somente conteúdo acessível publicamente] a fim de perceber sentimento, intenção, 
                                disposição e tendências de mercado, além das necessidades de nossos stakeholders para, 
                                dessa forma, melhorar nossos serviços)
                            </TdStyled>
                            <TdStyled>Justifica-se com base em nossos legítimos interesses em proteger nossos ativos e nossa marca nas mídias sociais.</TdStyled>
                        </TrStyled>
                        <TrStyled>
                            <TdStyled>Melhorar a segurança e o funcionamento do nosso website, aplicativo, redes e informações.</TdStyled>
                            <TdStyled>Justifica-se com base em nossos legítimos interesses de assegurar que você terá uma experiência de usuário excelente e que nossas redes e informações são seguras.</TdStyled>
                        </TrStyled>
                        <TrStyled>
                            <TdStyled>Oferecer nossos produtos e serviços para você (a menos que você tenha mostrado objeção em relação a tal tratamento).</TdStyled>
                            <TdStyled>Justifica-se com base em nossos legítimos interesses de promover nosso negócio.</TdStyled>
                        </TrStyled>
                        <TrStyled>
                            <TdStyled>Comentários e depoimentos de usuários</TdStyled>
                            <TdStyled>Baseia-se no consentimento do titular do dado pessoal.</TdStyled>
                        </TrStyled>
                        <TrStyled>
                            <TdStyled>Administrar benefícios aos empregados.</TdStyled>
                            <TdStyled>Justifica-se com base no consentimento do empregado, caso seja de seu interesse o recebimento de determinado benefício.</TdStyled>
                        </TrStyled>
                        <TrStyled>
                            <TdStyled>Entrega de informações necessárias aos órgãos governamentais.</TdStyled>
                            <TdStyled>Para cumprimento de uma obrigação legal.</TdStyled>
                        </TrStyled>
                        <TrStyled>
                            <TdStyled>Pesquisas na área da saúde</TdStyled>
                            <TdStyled>Baseia-se no consentimento do titular do dado pessoal, ou poderá ser realizado com a anonimização dos dados pessoais.</TdStyled>
                        </TrStyled>
                        <TrStyled>
                            <TdStyled>Telemedicina</TdStyled>
                            <TdStyled>Para a garantia da prevenção à fraude e à segurança dos titulares, nos processos de identificação e autenticação de cadastro em sistemas eletrônicos</TdStyled>
                        </TrStyled>
                    </tbody>
                </TableStyled>
                <p>
                    Apenas nos baseamos em nosso legítimo interesse para finalidades específicas. Estes tratamentos de dados pessoais não se sobrepõem de forma alguma aos interesses, direitos e liberdades dos titulares de dados pessoais.
                </p>

                <TitleStyled>5. Sobre o compartilhamento e cessão de informações</TitleStyled>
                <p>A <strong>EYECARE</strong> não tem como prática a divulgação de informações que podem identificar o usuário e jamais compartilha informações ou vende e aluga esses dados pessoais a terceiros. Estes dados são de uso exclusivo interno da empresa para atingir as finalidades expressas no item anterior.</p>
                <p>Os dados poderão ser compartilhados com terceiros apenas nas seguintes condições:</p>

                <ListStyled>
                    <li>Mediante decisão judicial ou solicitação pelos órgãos de fiscalização governamentais;</li>
                    <li>Dados transferidos a órgãos públicos para atender a legislação vigente, por exemplo dados pessoais que constem das notas fiscais eletrônicas e respectivos XMLs, ou dados dos nossos empregados necessários para o pagamento do INSS ou FGTS;</li>
                    <li>Dados transferidos a prestadores de serviço de contabilidade ou recursos humanos para o atendimento de obrigações fiscais e trabalhistas;</li>
                    <li>Dados transferidos a instituições financeiras para viabilizar opções de pagamento aos nossos clientes, ou para pagamento de salários aos nossos empregados e prestadores;</li>
                    <li>
                        Dados compartilhados com parceiros, operadores e fornecedores de serviços que participam, direta ou indiretamente, do desenvolvimento das atividades comerciais da <strong>EYECARE</strong>, tais como: serviços de atendimento ao cliente/paciente, cobranças, 
                        processos de pagamento, profissionais da área da saúde, clínicas médicas e hospitais oftalmológicos, órgãos de pesquisa da Administração Pública Direta ou Indireta ou pessoa jurídica de direito privado sem fins lucrativos legalmente 
                        constituída sob as leis brasileiras, serviços de envio de e-mails, publicidade e marketing, segurança e monitoramento de desempenho, serviços de reparos e manutenção, processamento e cumprimento de ordens e transações, verificação de 
                        informações cadastrais de consumidores, entidades que atuam na área de pesquisa de mercado, guarda e processamento de dados e auditoria interna ou externa;
                    </li>
                    <li>
                        Dados transferidos para fins de execução do contrato, bem como para a proteção dos interesses da <strong>EYECARE</strong> em qualquer tipo de conflito, incluindo ações judiciais.
                    </li>
                    <li>
                        Em caso de operações societárias como fusão, aquisição, transformação, cisão, ou uma venda parcial ou total de ativos, poderemos compartilhar, divulgar ou transferir todos os dados dos titulares para a organização sucessora.
                    </li>
                </ListStyled>
                
                <p>
                    Alguns dos prestadores de serviços mencionados acima podem estar localizados no exterior e, nesse caso, a <strong>EYECARE</strong> adota salvaguardas adicionais para a garantia de um nível adequado de proteção de dados pessoais, de acordo com o disposto na legislação brasileira pertinente.
                </p>

                <TitleStyled>6. Segurança da Informação</TitleStyled>
                <p>
                    Para assegurar-se de que as suas informações pessoais estão seguras, nós comunicamos as nossas diretrizes de privacidade e segurança aos empregados e parceiros comerciais da <strong>EYECARE</strong> e seguimos estritamente as medidas de precaução com relação à privacidade dentro da empresa.
                </p>
                <p>
                    Nós nos empenhamos em proteger as suas informações pessoais, e aquelas confiadas a nós pelos nossos clientes, por meio de medidas físicas, técnicas e organizacionais que visem reduzir os riscos de perda, mau uso, acesso não autorizado, divulgação e alteração indevida destes dados.
                </p>

                <TitleStyled>7. Direitos dos Titulares</TitleStyled>
                <p>Os Titulares de dados pessoais têm alguns direitos no que se refere aos seus dados pessoais e podem exercê-los <a href="https://forms.monday.com/forms/513dc5246a7e171b4504a94197820626?r=use1"  target="_blank">clicando aqui para acessar o Formulário de Requisição de Dados (DSAR) </a> ou por meio do e-mail: dpo@eyecarehealth.com.br</p>
                <p>São direitos dos titulares:</p>

                <ListStyled>
                    <li>Confirmação da existência de tratamento de dados pessoais;</li>
                    <li>Acesso aos dados pessoais, nos termos da legislação aplicável;</li>
                    <li>Correção de dados pessoais incompletos, inexatos ou desatualizados;</li>
                    <li>Anonimização em atividades realizadas em razão de pesquisa, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto nesta Lei;</li>
                    <li>Portabilidade dos dados pessoais;</li>
                    <li>Exclusão de dados pessoais, quando este forem tratados com base no consentimento do titular ou quando os dados forem desnecessários, excessivos ou tratados em desconformidade com a legislação aplicável;</li>
                    <li>Solicitação de informações sobre o uso compartilhado de dados pessoais;</li>
                    <li>Revogação do consentimento, quando aplicável.</li>
                </ListStyled>

                <p>
                    A <strong>EYECARE</strong> sempre avaliará a melhor forma de cumprir a solicitação de exercício de algum de seus direitos. No entanto, a <strong>EYECARE</strong> poderá deixar de atender a sua solicitação, total ou parcialmente, em situações específicas resguardadas pela legislação, como, por exemplo, para o cumprimento de uma obrigação legal ou de um contrato que mantém com você.
                </p>
                <p>
                    Ressaltamos a importância de manter seus dados pessoais precisos e atualizados. Para tanto, mantenha a <strong>EYECARE</strong> sempre informada se seus dados pessoais mudarem ou estiverem incorretos.
                </p>
                <p>
                    Por motivos de segurança, para as requisições que sejam feitas por meio do e-mail dpo@eyecarehealth.com.br, a solicitação será atendida quando tivermos certeza da identidade do usuário. Sendo assim, poderemos solicitar dados ou informações adicionais para a confirmação da identidade e da autenticidade do titular. Estes dados e informações serão protegidos durante o período de armazenamento e eliminados, após transcurso do prazo legal para eventual exercício regular de direitos. 
                </p>
                <p>
                    Nos casos em que a <strong>EYECARE</strong> for Operador de dados pessoais, as requisições serão repassadas ao Controlador para que este avalie e decida quanto às providências a serem tomadas.
                </p>

                <TitleStyled>8. Término de Tratamento</TitleStyled>
                <p>
                    Este Aviso de Privacidade se aplica às circunstâncias acima mencionadas durante todo o período em que a <strong>EYECARE</strong> armazenar os dados pessoais. Nós armazenamos e mantemos suas informações: (a) pelo tempo exigido por lei; (b) até o término do tratamento de dados pessoais, conforme mencionado abaixo; ou (c) pelo tempo necessário para resguardar os direitos da <strong>EYECARE</strong>. Assim, trataremos seus dados, por exemplo, durante os prazos prescricionais aplicáveis ou enquanto necessário para cumprimento de obrigação legal ou regulatória.
                </p>
                <p>
                    O término do tratamento de dados pessoais ocorrerá nos seguintes casos: (a) quando a finalidade para qual o dado pessoal foi coletado for alcançada, e/ou os dados pessoais coletados deixarem de ser necessários ou pertinentes ao alcance de tal finalidade; (b) quando o Titular solicitar a exclusão de seus dados; (c) quando houver uma determinação legal neste sentido e (d) quando o término do tratamento for determinado pelo Controlador.
                </p>
                <p>
                    Nos casos de término de tratamento de dados pessoais, ressalvadas as hipóteses estabelecidas pela legislação aplicável ou pelo presente Aviso de Privacidade, os dados pessoais serão eliminados.
                </p>

                <TitleStyled>9. Encarregado de Dados Pessoais (DPO)</TitleStyled>
                <p>
                    A <strong>EYECARE</strong> disponibiliza abaixo os dados de contato do Encarregado de Dados Pessoais (DPO), sendo este o responsável por atender toda e qualquer solicitação por parte dos titulares ou da Autoridade Nacional, que estejam relacionados com dados pessoais.
                </p>
                <p>Para qualquer dúvida, solicitação ou reclamação referente ao tratamento de dados pessoais, favor entrar em contato com o nosso Encarregado de Dados Pessoais:</p>

                <TitleStyled>DPO EXPERT – (<a href="https://dpoexpert.com.br" target="_blank">www.dpoexpert.com.br</a>)</TitleStyled>
                <p>
                    Responsável: Rafael Susskind <br/>
                    contato: dpo@eyecarehealth.com.br
                </p>
                <p>
                    Se, apesar do nosso compromisso e esforços para proteger seus dados, você achar que seus direitos de proteção de dados não foram atendidos, solicitamos que entre em contato com o nosso DPO. Além disso, você tem o direito, a qualquer momento, de registrar uma queixa diretamente com a Autoridade Nacional de Proteção de Dados, caso entenda que os direitos sobre os seus dados pessoais foram infringidos.
                </p>
                <TitleStyled>10. Alterações no Aviso de Privacidade</TitleStyled>
                <p>Apesar de nosso Aviso de Privacidade ter sido apresentada de forma clara, concisa e objetiva, não hesite em consultar o DPO da <strong>EYECARE</strong>, caso sobrevenham dúvidas sobre este importante documento ou mesmo sobre as atividades de tratamento de dados pessoais que realizamos.</p>
                <p>
                    A <strong>EYECARE</strong> se reserva no direito de atualizar ou modificar este Aviso, em qualquer época e sem aviso prévio. No entanto, publicaremos sempre a nova versão revisada em nosso website ou aplicativos. Caso existam alterações na forma em que tratamos dados pessoais, você será informado para verificar se pretende continuar adquirindo nossos produtos ou utilizando nossos serviços.
                </p>
            </SectionStyled>
        </MainStyled>
    );
}

export default PrivacyNotice;
