import Grid from "@mui/material/Grid"
import Logo from '../Logo'
import Button from "../Button"
import isoNegative from '../../assets/images/iso-negative.png'

import { FooterStyle, DivColorsFooter } from './styles'

export default function Footer() {
    return (
        <>
            <FooterStyle>
                <Grid container spacing={2} className="grid-icons">
                    <Grid item xs={12} md={2}>
                        <Logo
                            color="#FFFFFF"
                            width={150}
                            height={30}
                        />
                    </Grid>
                    <Grid item className="social-media" xs={12} md={2}>
                        <a className="svg" href="https://www.linkedin.com/company/eyecare-health/" target="_blank" rel="noreferrer">
                            <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 20.5C0 9.45431 8.95431 0.5 20 0.5V0.5C31.0457 0.5 40 9.45431 40 20.5V20.5C40 31.5457 31.0457 40.5 20 40.5V40.5C8.95431 40.5 0 31.5457 0 20.5V20.5Z" fill="#E7ECFD" />
                                <path d="M26 12.5H14C12.8954 12.5 12 13.3954 12 14.5V26.5C12 27.6046 12.8954 28.5 14 28.5H26C27.1046 28.5 28 27.6046 28 26.5V14.5C28 13.3954 27.1046 12.5 26 12.5Z" stroke="#305BF2" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M24 24.5V21.5C24 20.9696 23.7893 20.4609 23.4142 20.0858C23.0391 19.7107 22.5304 19.5 22 19.5C21.4696 19.5 20.9609 19.7107 20.5858 20.0858C20.2107 20.4609 20 20.9696 20 21.5M16 19.5V24.5V19.5ZM16 16.5V16.51V16.5ZM20 24.5V19.5V24.5Z" stroke="#305BF2" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </a>

                        <a href="https://www.instagram.com/eyecare.health/" target="_blank" rel="noreferrer">
                            <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 20.5C0 9.45431 8.95431 0.5 20 0.5C31.0457 0.5 40 9.45431 40 20.5C40 31.5457 31.0457 40.5 20 40.5C8.95431 40.5 0 31.5457 0 20.5Z" fill="#E7ECFD" />
                                <path d="M24 12.5H16C13.7909 12.5 12 14.2909 12 16.5V24.5C12 26.7091 13.7909 28.5 16 28.5H24C26.2091 28.5 28 26.7091 28 24.5V16.5C28 14.2909 26.2091 12.5 24 12.5Z" stroke="#305BF2" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M20 23.5C21.6569 23.5 23 22.1569 23 20.5C23 18.8431 21.6569 17.5 20 17.5C18.3431 17.5 17 18.8431 17 20.5C17 22.1569 18.3431 23.5 20 23.5Z" stroke="#305BF2" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M24.5 16V16.001" stroke="#305BF2" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </a>
                    </Grid>

                    <Grid item className="push" xs={12} md={2}>
                        <Button
                            text="Quero contratar"
                            theme="secondary"
                            width="120%"
                            height="53px"
                            route="/produtos/empresas/quero-contratar?type=hospital"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} className="main-grid">
                    <Grid item xs={12} sm={6} lg={4} xl={2} className="grid">
                        <h4 className="text-footer title">
                            Produtos
                        </h4>
                        
                        <a className="text-footer simple-text-link" href="https://conteudo.eyecarehealth.com.br/beneficio.visao">Para empresas</a>
                        <a className="text-footer simple-text-link" href="/produtos/empresas/clinicas-e-hospitais">Para clínicas e hospitais</a>
                        <a className="text-footer simple-text-link" href="/produtos/meu-cuidado-ocular">Para o meu cuidado ocular</a>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} xl={2} className="grid">
                        <h4 className="text-footer title">
                            Empresa
                        </h4>
                        
                        <a className="text-footer simple-text-link" href="/sobre-nos">Sobre nós</a>
                        <a className="text-footer simple-text-link" href="/termos-e-politicas">Termos e políticas</a>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} xl={2} className="grid">
                        <h4 className="text-footer title">
                            Conteúdos
                        </h4>
                        <a className="text-footer simple-text-link" href="https://blog.eyecarehealth.com.br/" target="_blank" rel="noreferrer">
                            Blog
                        </a>
                        {/* <Link href="/FAQ">
                            <a className="text-footer simple-text-link">FAQ</a>
                        </Link> */}
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} xl={2} className="grid">
                        <h4 className="text-footer title">
                            Acessos
                        </h4>
                        
                        <a className="text-footer simple-text-link" href="/exames">Exames</a>
                        
                        <a className="text-footer simple-text-link" href="https://teleoftalmologia.eyecarehealth.com.br/" target="_blank" rel="noreferrer">
                            Teleoftalmologia
                        </a>
                        <a className="text-footer simple-text-link" href="https://eyecarebi.eyecarehealth.com.br/" target="_blank" rel="noreferrer">
                            Acesso Médicos
                        </a>
                        
                        <a className="text-footer simple-text-link" href="/acesso-medico"></a>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} xl={2} className="grid">
                        <h4 className="text-footer title">
                            Contatos
                        </h4>
                        <div className="div-contact">
                            <a className="text-footer contact-text" href="mailto:suporte@eyecarehealth.com.br" title="suporte@eyecarehealth.com.br" alt="suporte@eyecarehealth.com.br" rel="noreferrer" target="_blank">suporte@eyecarehealth.com.br</a>
                            <span className="contact-text">11 93347-7836</span>
                        </div>
                        <div className="div-contact">
                            <a className="text-footer contact-text" href="mailto:contato@eyecarehealth.com.br" title="contato@eyecarehealth.com.br" alt="contato@eyecarehealth.com.br" rel="noreferrer" target="_blank">contato@eyecarehealth.com.br</a>
                            <span className="text-footer contact-text">11 93453-5994</span>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={4} lg={2} className="grid iso">
                        <div style={{ color: 'white' }}>Somos ISO 27001</div>
                        <img width={150} src={isoNegative} alt="ISO 15189" />
                    </Grid>
                </Grid>

                <Grid container spacing={2} className="reverse-mobile">
                    <Grid item xs={12} md={5} className="grid-copyright">
                        <span className="text-footer text-copyright">© {new Date().getFullYear()} Eyecare DN Soluções Digitais LTDA - CNPJ 35.800.459/0001-54</span>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <a className="text-footer decorate" href="/termos-e-politicas/aviso-de-privacidade">Aviso de Privacidade</a>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <a className="text-footer decorate" href="/termos-e-politicas/termos-de-uso">Termos de Uso</a>
                    </Grid>
                    <Grid item xl={12} md={2}>
                        <a className="text-footer decorate" href="/termos-e-politicas/politica-de-cookies">Política de Cookies</a>
                    </Grid>
                </Grid>
            </FooterStyle>

            <DivColorsFooter>
                <Grid container gap={0}>
                    <div className="colors dark-orange"></div>
                    <div className="colors light-orange"></div>
                    <div className="colors yellow"></div>
                    <div className="colors light-blue"></div>
                    <div className="colors dark-blue"></div>
                </Grid>
            </DivColorsFooter>
        </>
    )
}